
import { MenuItem, MenuList, Stack, Paper, Box } from '@mui/material';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { BASE_URL } from '../../Settings';
import { Button } from '@mui/material';
import '../../css/ChargeLocation.css';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { isCompanyContextAtom } from '../../Header';
import { atom, useAtom, useSetAtom } from 'jotai';


const Stripe = () => {
	var token = Cookies.get("token");
	localStorage.setItem('activePage', 'Stripe');
	var decoded = jwt_decode(token);
	var ownerGuid = Cookies.get("ownerGuid");
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
	const [connectedByMobile, setConnectedByMobile] = useState(false);
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});

		const fetchData = async () => {
			try {
				const customerApi = `/Customer/GetCustomer/${ownerGuid}/${token}`;
				const companyApi = `/Company/GetCompany/${ownerGuid}/${token}`;

				const response = await api.get(isCompanyContext ? companyApi : customerApi);
				
				// Handle the response here
				console.log("response", response)
				setData(response.data);
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		fetchData();
	}, []);

	const handleChargeLocationClick = () => {
		if (data.id) {
			navigate('/ChargeLocation');
			setConnectedByMobile(true);

		}
		navigate("/ConnectMobile");

	};
	

	return (
		<>

			<table style={{ width: "100%" }}>
				<Row>
					
					<Col xs={24} md={16}>
						
						



								{!data.stripeId ? (
									<>
										
										<p>
											<a href={data.stripeRegisterLink} className="connect-button">
												<span>{t('Connect+ mit Stripe')}</span>
											</a>
										</p>
										
									</>
								) : (

										<>
											<p style={{ fontSize: "larger", fontFamily:"Segoe UI semibold" }}>
												<span>{t('Your account is connected to our payment service provider Stripe')}</span>
										</p>
										<p>
											<a target="_blank" href="https://dashboard.stripe.com/login" className="connect-button">
													<span> {t('Log in to Stripe')}</span>
											</a>
										</p>
										
										
									</>

								)}



							

						
							
						
					</Col>
				</Row>

			</table>


		</>
	);
};

export default Stripe;