import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { BASE_URL, BLOB_URL } from '../../Settings';
import "../../css/Details.css";
import Cookies from 'js-cookie';
//import { Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Checkbox, Button } from '@mui/material';

//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
//import Paper from '@mui/material/Paper';
import Header from '../../Header';
import { Divider } from 'antd';
import { useParams } from 'react-router-dom';


function Details() {
    const { id } = useParams();
    const { state } = useLocation();
    const [rows, setRows] = useState([]);
    const [name, setName] = useState([]);
    const [booking, setBookings] = useState();
    var token = Cookies.get("token");



    useEffect(() => {
        const fetchData = async () => {
            let bookingGuid;

            if (state?.rowKey !== null && state?.rowKey !== undefined) {
                bookingGuid = state.rowKey;

            } else {
                bookingGuid = id;
            }

            if (bookingGuid) {
                try {
                    const bookingResponse = await axios.get(`${BASE_URL}/Public/GetBooking/${bookingGuid}`);
                    console.log("bookingResponse", bookingResponse.data);
                    setBookings(bookingResponse.data);

                    var friendGuid = bookingResponse.data.friendGuid;
                    console.log(friendGuid);

                    const activitiesResponse = await axios.get(`${BASE_URL}/Public/Activities/${bookingGuid}/${friendGuid}`);
                    console.log("activities", activitiesResponse.data);
                    setRows(activitiesResponse.data);

                    const chargePointResponse = await axios.get(`${BASE_URL}/Public/GetChargePoint/${bookingResponse.data.cpGuid}`);
                    console.log("chargepoint", chargePointResponse.data);
                    setName(chargePointResponse.data);
                } catch (error) {
                    console.log(error);
                }
            }
        };

        fetchData();
    }, []);


    var sunshinerate = booking?.sunShineRate;
    var formattedRate = (sunshinerate / 100).toLocaleString("DE", { style: "currency", currency: "EUR" });

    //useEffect(() => {

    //}, []);
    function formatDateTime(dateString, timeZone) {
        let time = new Date(); // Get the current date and time in UTC
        console.log("dateString", dateString)
        console.log("timeZone", timeZone)

        if (dateString) {
            time = new Date(Date.parse(dateString)); // Parse the provided dateString
        }
        if (timeZone) {
            const timeZoneOffset = new Date().getTimezoneOffset();
            const timeZoneOffsetMinutes = timeZoneOffset + parseInt(timeZone) * 60; // Calculate the time zone offset in minutes
            time = new Date(time.getTime() + timeZoneOffsetMinutes * 60 * 1000); // Adjust the date and time according to the timeZone
        }

        // Format the date and time as "dd.MM.yyyy, HH:mm:ss"
        const formattedDate = time.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });

        const formattedTime = time.toLocaleTimeString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });

        return `${formattedDate}, ${formattedTime}`;
    }
    const nameCp = `${name.cpName} - ${name.name}`;





    return (
        <div>
            <Header></Header>
            <h1 style={{
                margin: "3% 5%", borderColor: "white", fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                fontSize: '3.5rem',
                fontWeight: 300,
                lineHeight: 1.2,
            }} >Ladevorgang</h1>
            <div className="table" style={{ margin: "3% 5%", borderColor: "white", pointerEvents: 'none' }} >
                <div className="row">
                    <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginBottom: '20px' }}>
                            <label style={{
                                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                                fontSize: '1.5rem',
                                fontWeight: 400,
                                lineHeight: 1.5,
                            }}>Ladepunkt Name</label>
                        </div>
                        <div>
                            <input className="form-control" readOnly style={{
                                backgroundColor: "#e9ecef",
                                opacity: 1,
                                display: 'block',
                                width: '49%',
                                height: 'calc(1.5em + 0.75rem + 2px)',
                                padding: '0.375rem 0.75rem',
                                fontSize: '1rem',
                                fontWeight: 400,
                                lineHeight: 1.5,
                                color: '#495057',
                                backgroundClip: 'padding-box',
                                border: '1px solid #ced4da',
                                borderRadius: '0.25rem',
                                transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',

                            }} name="name" value={nameCp} />
                        </div>
                    </div>
                </div>
                <div className="table"  >
                    <div className="row">
                        <div className="col-md-4">
                            <div className="table" style={{ width: "50%" }}>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6">
                                        <label><b>Max. Leistung</b></label>
                                    </div>
                                    <div className="col-md-6 right">
                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                            {(name.power, 2).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 1 })} kW
                                            {/* {Math.round(name.power, 2).toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 1 })} kW */}
                                        </label>
                                    </div>
                                </div>
                                {booking?.kWhBeforePv > 0 || booking?.kWhDuringPv > 0 || booking?.kWhAfterPv > 0 ? (
                                    <>
                                        {booking?.kWhDuringPv > 0 && (
                                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                                <div className="col-md-6">
                                                    <label><b>PV/Tag-Tarif</b></label>
                                                </div>
                                                <div className="col-md-6 right">
                                                    <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>{formattedRate}</label>
                                                </div>
                                            </div>
                                        )}
                                        {(booking?.kWhBeforePv > 0 || booking?.kWhAfterPv > 0) && (
                                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                                <div className="col-md-6">
                                                    <label><b>Netz/Nacht-Tarif</b></label>
                                                </div>
                                                <div className="col-md-6 right">
                                                    <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                                        {/* {
                                                            booking?.kWhPrice !== undefined
                                                                ? Math.round(booking?.moonShineRate / 100, 2).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + '/kWh' : ''
                                                        } */}
                                                        {
                                                            booking?.kWhPrice !== undefined
                                                                ? (booking?.moonShineRate / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + '/kWh' : ''
                                                        }

                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row" style={{ display: 'flex', justifyContent: 'space-between', width: "100%", margin: '20px 0' }} >
                                            <div className="col-md-6" style={{ width: '50%' }} >
                                                <Divider />
                                            </div>
                                            <div style={{ width: '20px' }}>
                                                {/* Create the horizontal space of 20 pixels between the hr elements */}
                                            </div>
                                            <div className="col-md-6 right" style={{ width: '50%' }}>
                                                <Divider />
                                            </div>
                                        </div>
                                        <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                            <div className="col-md-6">
                                                <label><b>Zählerstände:</b></label>
                                            </div>
                                            <div className="col-md-6 right">
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                        <div className="col-md-6">
                                            <label><b>Tarif:</b></label>
                                        </div>
                                        <div className="col-md-6 right">
                                            <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                                {booking?.kWhPrice !== undefined
                                                    ? (booking?.kWhPrice / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + '/kWh'
                                                    : ''}

                                                {/* {booking?.kWhPrice !== undefined
                                                        ? Math.round(booking?.kWhPrice / 100, 2).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) + '/kWh'
                                                    : ''} */}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6">
                                        <label><b>Start Ladevorgang</b></label>
                                    </div>
                                    <div className="col-md-6 right">
                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                            {/* {Math.round(booking?.meterStartValue, 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                            {booking?.meterStartValue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                        </label>
                                    </div>
                                </div>
                                {booking?.meterStartPvValue
                                    > 0 && (
                                        <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                            <div className="col-md-6">
                                                <label><b>Beginn PV-Tarif</b>
                                                    {booking.pvSTime != null && (
                                                        <div><br /><sup>({formatDateTime(booking?.pvSTime, booking?.timeDiff)})</sup></div>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="col-md-6 right">
                                                <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                                    {booking?.meterStartPvValue?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                                    {/* {Math.round(booking?.meterStartPvValue
                                                        , 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                {booking?.meterEndPvValue > 0 && (
                                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                        <div className="col-md-6">
                                            <label><b>Ende PV-Tarif</b>
                                                {booking.pvETime != null && (
                                                    <div> <br /><sup>({formatDateTime(booking?.pvETime, booking?.timeDiff)})</sup></div>
                                                )}</label>
                                        </div>
                                        <div className="col-md-6 right">
                                            <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                                {booking?.meterEndPvValue?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                                {/* {Math.round(booking?.meterEndPvValue, 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div >
                                        <div className="col-md-6">
                                            <label><b>Ende Ladevorgang</b></label>
                                        </div>
                                    </div>
                                    <div >
                                        <div className="col-md-6 right">
                                            <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                                {booking?.meterEndValue?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                                {/* {Math.round(booking?.meterEndValue
                                                    , 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }} >
                                    <div className="col-md-6" style={{ width: '50%' }} >
                                        <Divider />
                                    </div>
                                    <div style={{ width: '20px' }}>
                                        {/* Create the horizontal space of 20 pixels between the hr elements */}
                                    </div>
                                    <div className="col-md-6 right" style={{ width: '50%' }}>
                                        <Divider />
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6">
                                        <label><b>Geladener Strom:</b></label>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6">
                                        <label><b>Insgesamt</b></label>
                                    </div>
                                    <div className="col-md-6 right">
                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                            {booking?.chargedKWh?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kWh

                                            {/* {Math.round(booking?.chargedKWh
                                                , 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kWh */}
                                        </label>
                                    </div>
                                </div>
                                {booking?.kWhDuringPv > 0 || (booking?.kWhBeforePv > 0 || booking?.kWhAfterPv > 0) ? (
                                    <>
                                        {booking?.kWhDuringPv > 0 && (
                                            <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                                <div className="col-md-6">
                                                    <label><b>PV-Tarif:</b></label>
                                                </div>
                                                <div className="col-md-6 right">
                                                    <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                                        {booking?.kWhDuringPv?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kWh

                                                        {/* {Math.round(booking?.kWhDuringPv, 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kWh */}
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        {(booking?.kWhBeforePv > 0 || booking?.kWhAfterPv > 0) && (
                                            <>
                                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                                    <div className="col-md-6">
                                                        <label><b>Netz-Tarif:</b></label>
                                                    </div>
                                                    <div className="col-md-6 right">
                                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                                            {(booking?.kWhBeforePv + booking?.kWhAfterPv).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kWh

                                                            {/* {Math.round((booking?.kWhBeforePv + booking?.kWhAfterPv), 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kWh */}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                                    <div className="col-md-6">
                                                        <label><b>Stromkosten Netz/Nacht-Tarif:</b></label>
                                                    </div>
                                                    <div className="col-md-6 right">
                                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>

                                                            {(((booking?.kWhBeforePv + booking?.kWhAfterPv) * booking?.moonShineRate) / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}

                                                            {/* {Math.round(((booking?.kWhBeforePv + booking?.kWhAfterPv) * booking?.moonShineRate) / 100, 2).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} */}
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="row" style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }} >
                                            <div className="col-md-6" style={{ width: '50%' }} >
                                                <Divider />
                                            </div>
                                            <div style={{ width: '20px' }}>
                                                {/* Create the horizontal space of 20 pixels between the hr elements */}
                                            </div>
                                            <div className="col-md-6 right" style={{ width: '50%' }}>
                                                <Divider />
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <label>Start</label>
                                    </div>
                                    <div className="col-md-6 " style={{ marginLeft: '10px' }}>
                                        <label className="output" style={{ backgroundColor: '#c0ffe0', borderRadius: "4px", padding: "6px 12px" }}>{formatDateTime(booking?.startTime, booking?.timeDiff)}</label>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <label>Ende</label>
                                    </div>
                                    <div className="col-md-6" style={{ marginLeft: '10px' }}>
                                        <label className="output" style={{ backgroundColor: '#ffc0c0', borderRadius: "4px", padding: "6px 12px" }}>{formatDateTime(booking?.endTime, booking?.timeDiff)}</label>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <label>Zähler (Start)</label>
                                    </div>
                                    <div className="col-md-6" style={{ marginLeft: '10px' }}>
                                        <label className="output" style={{ backgroundColor: '#c0ffe0', borderRadius: "4px", padding: "6px 12px" }}>{booking?.meterStartValue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>



                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <label>Zähler (Ende)</label>
                                    </div>
                                    <div className="col-md-6" style={{ marginLeft: '10px' }}>
                                        <label className="output" style={{ backgroundColor: '#ffc0c0', borderRadius: "4px", padding: "6px 12px" }}>{booking?.meterEndValue
                                            .toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</label>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6" style={{ marginRight: '10px' }}>
                                        <label>Geladene kWh</label>
                                    </div>
                                    <div className="col-md-6 " style={{ marginLeft: '10px' }}>
                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px", margin: '20px 0' }}>
                                            {/* {Math.round(booking?.chargedKWh
                                                , 2).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                            {booking?.chargedKWh?.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                                        </label>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6">
                                        <label><b>Betrag</b></label>
                                    </div>
                                    <div className="col-md-6 right">
                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                            {(booking?.chargePrice
                                                / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                                        </label>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }} >
                                    <div className="col-md-6" style={{ width: '50%', borderColor: "white" }} >
                                        <Divider />
                                    </div>
                                    <div style={{ width: '20px' }}>
                                        {/* Create the horizontal space of 20 pixels between the hr elements */}
                                    </div>
                                    <div className="col-md-6 right" style={{ width: '50%', borderColor: "white" }}>
                                        <Divider />

                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    <div className="col-md-6">
                                        <label><b>Summe (Mindestumsatz 50ct)</b></label>
                                    </div>
                                    <div className="col-md-6 ">
                                        <label className="output" style={{ backgroundColor: "#eee", borderRadius: "4px", padding: "6px 12px" }}>
                                            {(booking?.sumPrice / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                                        </label>
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
                                    {/*<div className="col-md-6">*/}
                                    {/*    <label><b>enthaltene mwst (@{mwstprice * 100}%)</b></label>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-6 right">*/}
                                    {/*    <label className="output">{booking.mwstprice}</label>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" style={{ margin: "3% 5%", borderColor: "white" }}>
                <h4>Log</h4>
                <Box component="div" sx={{ borderColor: "white", width: "70%" }} >
                    <TableContainer component={Paper} sx={{ borderColor: "white" }}>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead sx={{ border: "none" }}>
                                <TableRow sx={{ border: "none" }}>
                                    <TableCell sx={{ padding: '5px', border: "none", backgroundColor: "#5faa39", textAlign: 'center', fontWeight: 'bold'  }} >TimeStamp</TableCell>
                                    <TableCell sx={{ padding: '5px', border: "none", backgroundColor: "#5faa39", textAlign: 'center', fontWeight: 'bold'   }} align="right">StateChange</TableCell>
                                    <TableCell align="right" sx={{ padding: '5px', border: "none", backgroundColor: "#5faa39", textAlign: 'center', fontWeight: 'bold'   }}>MeterStart</TableCell>
                                    <TableCell align="right" sx={{ padding: '1px', border: "none", backgroundColor: "#5faa39", textAlign: 'center', fontWeight: 'bold'   }}>MeterEnd</TableCell>
                                    <TableCell align="right" sx={{ padding: '1px', border: "none", backgroundColor: "#5faa39", textAlign: 'center', fontWeight: 'bold'   }}>MeterPvStart</TableCell>
                                    <TableCell align="right" sx={{ padding: '1px', border: "none", backgroundColor: "#5faa39", textAlign: 'center', fontWeight: 'bold'  }}>MeterPvEnd</TableCell>
                                    <TableCell align="right" sx={{ padding: '1px', border: "none", backgroundColor: "#5faa39", textAlign: 'center', fontWeight: 'bold'   }}>Sum</TableCell>

                                </TableRow>
                            </TableHead>
                            {/* <TableBody>
                                {console.log("row",rows)}
                                {(rows!=null && rows.length>0)?rows.map((row) => {
                                    const shouldRenderPrice = true || row.delta.includes("PaymentStarted"); 
                                    return (
                                        <TableRow
                                            key={row.name}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >

                                            <TableCell sx={{ padding: '1px', border: "none" }}>{row.timeStampString}</TableCell>
                                            <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.delta}</TableCell>
                                            <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.meterStartValue}</TableCell>
                                            <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.meterEndValue}</TableCell>
                                            <TableCell align="right" sx={{ padding: '1px', border: "none" }}> {row.meterStartPvValue}</TableCell>
                                            <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.meterEndPvValue}</TableCell>
                                            {shouldRenderPrice && (
                                                <TableCell align="right" sx={{ border: "none" }}>{row.sumPrice}</TableCell>

                                            )}
                                        </TableRow>
                                    );
                                }):''}
                            </TableBody> */}

                            <TableBody>
                                {rows != null && rows.length > 0 ? rows
                                    .filter(row => row.delta != null && row.delta !== '') // Filter out rows with null or empty delta
                                    .map((row) => {
                                        const shouldRenderPrice = true || row.delta.includes("PaymentStarted");
                                        return (
                                            <TableRow
                                                key={row.name}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell sx={{ padding: '1px', border: "none" }}>{row.timeStampString}</TableCell>
                                                <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.delta}</TableCell>
                                                <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.meterStartValue}</TableCell>
                                                <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.meterEndValue}</TableCell>
                                                <TableCell align="right" sx={{ padding: '1px', border: "none" }}> {row.meterStartPvValue}</TableCell>
                                                <TableCell align="right" sx={{ padding: '1px', border: "none" }}>{row.meterEndPvValue}</TableCell>
                                                {shouldRenderPrice && (
                                                    <TableCell align="right" sx={{ border: "none" }}>{row.sumPrice}</TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    }) : ''}
                            </TableBody>


                        </Table>
                    </TableContainer>
                </Box>

            </div>
            <div class="row" style={{ display: 'flex', justifyContent: 'space-between', width: "70%", margin: "3% 5%", pointerEvents: 'none' }}  >
                <div class="col-md-4" style={{ display: 'flex', flexDirection: 'column', width: "30%" }} >
                    <div style={{ marginBottom: '20px' }}>
                        <label >Ladepunkt Foto</label>
                    </div>
                    <div>
                        <img style={{ width: "50%" }} src={name.thumbnailUrl}></img>
                    </div>
                </div>
                <div class="col-md-4" style={{ display: 'flex', flexDirection: 'column', width: "30%" }}>
                    <div style={{ marginBottom: '20px' }} >
                        <label>Foto Zählerstand Start</label>
                    </div>
                    <div class="meterPhoto" style={{
                        backgroundImage: `url(${BLOB_URL}/counterphotos/${booking?.rowKey}/StartCounterImage.jpg) `,
                    }}>
                    </div>
                    <span class="text-danger"></span>

                </div>
                <div class="col-md-4" style={{ display: 'flex', flexDirection: 'column', width: "30%" }}>
                    <div style={{ marginBottom: '20px' }} >
                        <label>Foto Zählerstand Ende</label>
                    </div>
                    <div class="meterPhoto" style={{
                        backgroundImage: `url(${BLOB_URL}/counterphotos/${booking?.rowKey}/EndCounterImage.jpg) `,
                    }}>

                    </div>
                    {/* <a style={{ width: "50%" }} href={`${BLOB_URL}/counterphotos/${booking?.rowKey}/EndCounterImage.jpg`}></a>*/}



                </div>
            </div>
        </div>
    );

}
export default Details;