import React, { useState, useEffect, useContext,useRef } from 'react';
import { Divider, Col, Row,Switch } from 'antd';
import { TextField, Box } from '@mui/material';
import { debounce } from 'lodash'

import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import axios from 'axios';
import { BASE_URL } from '../../Settings';
import { useLocation } from 'react-router-dom';
import "../../css/ChargeStationTabs.css";
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../node_modules/@mui/material/index';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Segmented } from 'antd';
import ChargeStationDataContext  from './ChargeStationDataContext';
import ChargeStationModel from './ChargeStationModel';
import ChargeStationConnection from './ChargeStationConnection';

import ChargeStationBilling from './ChargeStationBilling';
import ChargeStationMeter from './ChargeStationMeter';
import ChargeStationMapContainer from './ChargeStationMapContainer';
import ChargingStationProfiles from './ChargingStationProfiles';
import UploadPhotoPatternFly from './UploadPhotoPatternFly';
function ChargeStationDetails({ isActive }) {
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
   /* const chargeStationData= useContext(ChargeStationDataContext);*/
    const [showDetails, setShowDetails] = useState(false);
   /* const [latLng, setLatLng] = useState({ lat: null, lng: null });*/
    const [values, setValues] = useState(chargeStationData);
    console.log("chargeStationData in details*****", chargeStationData);
    
    const { t } = useTranslation();
    let Json = chargeStationData;
    console.log("chargeStationData in details***** json", Json);
    console.log("chargeStationData in details availabliity ", chargeStationData.available);
    const [isAvailable, setIsAvailable] = useState(chargeStationData.available);
    const { state } = useLocation();
    let valuesInitialState = chargeStationData;
    console.log("charge station data", values)

    const updateChargingStationAvailability = async (isAvailable) => {
        try {
            // Send PUT request to update availability
            await axios.put(`${BASE_URL}/CpoLocation/UpdateChargingStationAvailability/${Json.partitionKey}/${Json.guid}/${token}/${isAvailable ? 1 : 0}`);
            console.log('Charging station availability updated successfully!');
        } catch (error) {
            console.error('Error updating charging station availability:', error);
            // You can handle errors here, e.g., show a message to the user
        }
    };




    const handleToggleAvailability = async () => {
        const newAvailability = !isAvailable;
        setIsAvailable(newAvailability);
        
        const updatedChargeStationData = {
            ...values,
            available: newAvailability
        };
        setChargeStationData(updatedChargeStationData);
        // Update the availability using the new state
        await updateChargingStationAvailability(newAvailability ? 1 : 0);

    };



    console.log("charge station  data &&&&&", chargeStationData);
   
  
    console.log("initial Profile json", valuesInitialState)

    // Debounced update function
    const debouncedUpdateServer = debounce(updateServer, 5000); // Adjust the delay as needed

    const [isLoading, setIsLoading] = useState(false);

    const [rowKey, setRowKey] = useState(Json);

    var token = Cookies.get("token");
    var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");

    //#region  google map value and bind this value in textfield
    const [latLng, setgoogleLatLng] = useState({ lat: chargeStationData.lat, lng: chargeStationData.lon });
    const [showMap, setShowMap] = useState(false);


    const refresh = () => {
      
        setValues(chargeStationData);
        setIsAvailable(chargeStationData.available);
    };
    useEffect(refresh, [chargeStationData]);

    console.log("latLng hello ", latLng)
    //#endregion
    // Server update function
    function updateServer(lat,lng) {
        //UpdateFields(fields);
        // Perform the server update here using the fields object
       
        const updatedValues = {
            ...values,
            available: isAvailable ,
            lat: lat,
            lon:lng
        };
        console.log("old profile", updatedValues)


        fetch(`${BASE_URL}/CpoLocation/UpdateCockpitLocation/${valuesInitialState.partitionKey}/${valuesInitialState.guid}/${token}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedValues),

        }).then((response) => {
            if (response.ok) {
                return response.json(); // Parse the JSON response body
            } else {
                throw new Error('There was an error!');
            }
        }).then((updatedData) => {
            setValues(updatedData);
            setChargeStationData(updatedData);
            console.log("updatedData in chargeStation", updatedData); // Update context data
        })
        .catch ((error) => {
            console.error(error.message);
        });
    }


    // End validate textfields code
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    console.log("latLng in details", latLng);

    const handleChange = (input) => (e) => {
        const value = e.target.value || ''; // Set an empty string if the value is undefined
        console.log("handlechange", value)
        setValues({ ...values, [input]: value,available: isAvailable });
       setgoogleLatLng(latLng);



        //setErrors({ ...errors, [input]: '' }); // Clear the error for the current field
        // setRowKey({ ...values, [input]: value  }); // Update the rowKey state with the new value

    };

    const handleBlur = () => {
        console.log("profile lat", latLng)
       
        updateServer();
        
    };
    //const handleMapChange = (newLatLng) => {
    //    setLatLng(newLatLng);
    //};
    const mapContainerRef = useRef(null);
    const collapsibleDivs = useRef(null);
    const selectRef = useRef(null);
    // Function to handle clicks outside of the map container
    const handleClickOutside = (event) => {
        console.log('Clicked element:', event.target);
        if (mapContainerRef.current && !mapContainerRef.current.contains(event.target)) {
            console.log('Clicked element in maps:', event.target);
            setShowMap(false);
        } else if (collapsibleDivs.current && !collapsibleDivs.current.contains(event.target) &&!event.target.closest('.MuiPopover-root')) {
            console.log("Clicked outside, trigger collapse");
            setExpanded(false);
            setExpandedConnection(false);
            setExpandedBilling(false);
            setExpandedMeter(false);
            setExpandedOpeningHours(false);
        }

    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
   
    const [expanded, setExpanded] = useState(false);
    const [expandedConnection, setExpandedConnection] = useState(false);
    const [expandedBilling, setExpandedBilling] = useState(false);
    const [expandedMeter, setExpandedMeter] = useState(false);
    const [expandedOpeningHours, setExpandedOpeningHours] = useState(false);

    
    const handleToggle = () => {
        setExpanded(!expanded);
    };
    const handleToggleConnection = () => {
        setExpandedConnection(!expandedConnection);
    };
    const handleToggleBilling = () => {
        setExpandedBilling(!expandedBilling);
    };
    const handleToggleOpeningHours = () => {
        setExpandedOpeningHours(!expandedOpeningHours);
    };

    const handleToggleMeter = () => {
        setExpandedMeter(!expandedMeter);
    };
    if (isLoading) {
        return <div style={{
            padding: '2% 20%'
        }}> <h2>Loading...</h2>
        </div>;
    }
    return (
        <React.Fragment>

            <div style={{ width: '100%' }} class="sd-tab-content">
                <Box
                    component="div"
                    sx={{
                        backgroundColor: '#f5f5f5',
                        borderTop: "none",
                        border: '1px solid #e0e0e0',
                        borderRadius: '0.3vw',
                        margin:"20px"
                    }}
                >
                    <Row>

                        <Col xs={24} md={25}>
                            <div style={{ padding: '0% 1vw' }}>

                                <div style={{ padding: '2%' }}>
                                <div>
                                        <Row justify="space-between" align="middle">
                                 
                                    <Col>

                                        <h1 style={{ fontSize: '1.5rem',  lineHeight: 1 }}>
                                                    { t('Location') }
                                        </h1>
                                    </Col>
                                            <Col xs="auto">
                                                <Row gutter={[10, 25]}>
                                                <Col >
                                                <h1 style={{ fontSize: '1.5rem', lineHeight: 1 }}>
                                                    {t('Active')}
                                                    </h1>
                                                </Col>
                                                <Col>
                                                <Switch checked={isAvailable} onChange={handleToggleAvailability} />
                                                </Col>
                                                </Row>
                                        </Col>
                                    </Row>
                                    </div>
                                    <form>
                                        <div style={{ width: "100%" }}>

                                            
                                            <div>
                                                <Divider />
                                                <Row gutter={[10, 25]}>
                                                    <Col xs={24} md={12}>
                                                        <Row gutter={[10, 25]}>
                                                            <Col xs={24} md={12}>
                                                                <TextField
                                                                    id="ZipCode"
                                                                    label={t('Zip Code')}
                                                                    color="success"
                                                                    value={values?.zipCode || ''}
                                                                    onChange={handleChange('zipCode')}
                                                                    onBlur={handleBlur}
                                                                    focused
                                                                    fullWidth
                                                                    helperText={values.zipCode && values.zipCode.length > 5 ? t('Zip Code should be 5 characters') : ''}
                                                                    error={values.zipCode && values.zipCode.length > 5}
                                                                />
                                                                <Divider />
                                                            </Col>
                                                            <Col xs={24} md={12}>
                                                                <TextField
                                                                    id="City"
                                                                    fullWidth
                                                                    label={t('City')}
                                                                    color="success"
                                                                    value={values?.city || ''}
                                                                    onChange={handleChange('city')}
                                                                    onBlur={handleBlur}
                                                                    focused
                                                                />
                                                                <Divider />
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={[10, 25]}>
                                                            <Col xs={24} md={12}>
                                                                <TextField
                                                                    id="Street"
                                                                    fullWidth
                                                                    label={t('Street')}
                                                                    color="success"
                                                                    value={values?.street || ''}
                                                                    onChange={handleChange('street')}
                                                                    onBlur={handleBlur}
                                                                    focused
                                                                    helperText={values.street && values.street.length > 40 ? t('Street should be 40 characters') : ''}
                                                                    error={values.street && values.street.length > 40}
                                                                />
                                                                <Divider />
                                                            </Col>
                                                            <Col xs={24} md={12}>
                                                                <TextField
                                                                    id="StreetNumber"
                                                                    label={t('Street Number')}
                                                                    color="success"
                                                                    value={values?.streetNumber || ''}
                                                                    onChange={handleChange('streetNumber')}
                                                                    onBlur={handleBlur}
                                                                    focused
                                                                    fullWidth
                                                                    helperText={values.streetNumber && values.streetNumber.length > 4 ? t('Street Number should be 4 characters') : ''}
                                                                    error={values.streetNumber && values.streetNumber.length > 4}
                                                                />
                                                                <Divider />
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={[10, 25]}>
                                                            <Col xs={24} md={12}>
                                                                <TextField
                                                                    id="Address2"
                                                                    label={
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {t('Ladeplatz')}
                                                                            <Popover
                                                                                placement="rightTop"
                                                                                title={t("Charge location")}
                                                                                arrowPointAtCenter
                                                                                content={t('Name of your station, as it is displayed to users, e.g. �Ella-Power� (max. 16 digits)')}
                                                                                trigger="hover"
                                                                            >
                                                                                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                                                                            </Popover>
                                                                        </div>
                                                                    }
                                                                    color="success"
                                                                    value={values?.address2 || ''}
                                                                    onChange={handleChange('address2')}
                                                                    onBlur={handleBlur}
                                                                    helperText={values.address2 && values.address2.length > 16 ? t('Max. 16 characters') : ''}
                                                                    error={values.address2 && values.address2.length > 16}
                                                                    fullWidth
                                                                    focused
                                                                />
                                                            </Col>
                                                            <Col xs={24} md={12}>

                                                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', height: '100%' }}>
                                                                    <Button onClick={() => setShowMap(true)}>
                                                                        {console.log("lat and lon condition", values.lat !== 0 && values.lon !== 0)}
                                                                        {chargeStationData.lat !== 0 && chargeStationData.lon !== 0
                                                                            ? t('change position')
                                                                            : t('Search Position in Map')}
                                                                    </Button>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={24} md={12}>
                                                        <UploadPhotoPatternFly />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <Divider />
                                            {showMap &&
                                                <div >
                                                    <Row gutter={[10, 25]}>

                                                        <div ref={mapContainerRef}  className="map-container-wrapper" style={{ position: 'relative', width: '100%', paddingBottom: '40%' }}>
                                                            <ChargeStationMapContainer updateServer={updateServer} setLatLng={setgoogleLatLng} zipcode={values.zipCode} streetaddress={values.street} streetnumber={values.streetNumber} />
                                                        </div>
                                                    </Row>
                                                    <Divider />
                                                </div>

                                            } 
                                            <div ref={collapsibleDivs}>
                                            <Row gutter={[10, 25]}>
                                                <Col xs={24} md={12}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f0fff0',
                                                            borderRadius: '4px',
                                                            border: '1px solid #e0e0e0',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: '#f0fff0',
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                // Adjust vertical alignment and ensure padding consistency
                                                                boxSizing: 'border-box',
                                                            }}
                                                            onClick={handleToggle}
                                                        >
                                                            <h1 style={{ fontSize: '1.5rem', marginBottom: 0, lineHeight: 1 }}>
                                                                {t('Model')}
                                                            </h1>
                                                            <span>{expanded ? '-' : '+'}</span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                transition: 'height 0.6s ease-out', // Smooth transition
                                                                height: expanded ? 'auto' : '0',
                                                                backgroundColor: '#f0fff0',
                                                                padding: expanded ? '10px' : '0',
                                                                overflow: 'hidden',
                                                                // Adjust max-height to handle transition without overflow issues
                                                                maxHeight: expanded ? '1000px' : '0', // Example max-height, adjust if necessary
                                                            }}
                                                        >
                                                            {expanded && (
                                                                <Row gutter={[10, 25]}>
                                                                    <Col xs={24}>
                                                                            <ChargeStationModel selectRef={selectRef} />
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>


                                                <Col xs={24} md={12}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f0fff0',
                                                            borderRadius: '4px',
                                                            border: '1px solid #e0e0e0',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: '#f0fff0',
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                // Adjust vertical alignment and ensure padding consistency
                                                                boxSizing: 'border-box',
                                                            }}
                                                            onClick={handleToggleMeter}
                                                        >

                                                            <h1 style={{ fontSize: '1.5rem', marginBottom: 0, lineHeight: 1 }}>
                                                                {t('Meter & Power')}
                                                            </h1>
                                                          
                                                            <span>{expandedMeter ? '-' : '+'}</span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                transition: 'height 0.6s',
                                                                height: expandedMeter ? 'auto' : 0,
                                                                backgroundColor: '#f0fff0',
                                                                padding: expandedMeter ? '10px' : '0',
                                                                overflow: 'hidden',
                                                                // Adjust max-height to handle transition without overflow issues
                                                                // Example max-height, adjust if necessary
                                                            }}
                                                        >
                                                            {expandedMeter && (
                                                                <Row gutter={[10, 25]}>
                                                                    <Col xs={24}>
                                                                        <ChargeStationMeter />
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Divider />
                                            <Row gutter={[10, 25]}>
                                                <Col xs={24} md={12}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f0fff0',
                                                            borderRadius: '4px',
                                                            border: '1px solid #e0e0e0',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: '#f0fff0',
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                // Adjust vertical alignment and ensure padding consistency
                                                                boxSizing: 'border-box',
                                                            }}
                                                            onClick={handleToggleConnection}
                                                        >

                                                            <h1 style={{ fontSize: '1.5rem', marginBottom: 0, lineHeight: 1 }}>
                                                                {t('Online Connection')}
                                                            </h1>
                                                          
                                                            <span>{expandedConnection ? '-' : '+'}</span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                transition: 'height 0.6s',
                                                                height: expandedConnection ? 'auto' : 0,
                                                                backgroundColor: '#f0fff0',
                                                                padding: expandedConnection ? '10px' : '0',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {expandedConnection && (
                                                                <Row gutter={[10, 25]}>
                                                                    <Col xs={24}>
                                                                        <ChargeStationConnection />
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f0fff0',
                                                            borderRadius: '4px',
                                                            border: '1px solid #e0e0e0',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: '#f0fff0',
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                // Adjust vertical alignment and ensure padding consistency
                                                                boxSizing: 'border-box',
                                                            }}
                                                            onClick={handleToggleOpeningHours}
                                                        >
                                                            <h1 style={{ fontSize: '1.5rem', marginBottom: 0,  lineHeight: 1 }}>
                                                                {t('Opening Hours')}
                                                            </h1>
                                                            <span>{expandedOpeningHours ? '-' : '+'}</span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                transition: 'height 0.6s',
                                                                height: expandedOpeningHours ? 'auto' : 0,
                                                                backgroundColor: '#f0fff0',
                                                                padding: expandedOpeningHours ? '10px' : '0',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {expandedOpeningHours && (
                                                                <Row gutter={[10, 25]}>
                                                                    <Col xs={24}>
                                                                        <ChargingStationProfiles/>
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Divider />
                                            <Row>
                                                <Col xs={24} md={24}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f0fff0',
                                                            borderRadius: '4px',
                                                            border: '1px solid #e0e0e0',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: '#f0fff0',
                                                                padding: '10px',
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                // Adjust vertical alignment and ensure padding consistency
                                                                boxSizing: 'border-box',
                                                            }}
                                                            onClick={handleToggleBilling}
                                                        >

                                                            <h1 style={{ fontSize: '1.5rem', marginBottom: 0, lineHeight: 1 }}>
                                                                {t('Billing')}
                                                            </h1>
                                                           
                                                            <span>{expandedBilling ? '-' : '+'}</span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                transition: 'height 0.6s',
                                                                height: expandedBilling ? 'auto' : 0,
                                                                backgroundColor: '#f0fff0',
                                                                padding: expandedBilling ? '10px' : '0',
                                                                 overflow: 'hidden',
                                                            }}
                                                        >
                                                            {expandedBilling && (
                                                                <Row gutter={[10, 25]}>
                                                                    <Col xs={24}>
                                                                        <ChargeStationBilling />
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                                <Divider />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Col>

                    </Row></Box>
            </div>


        </React.Fragment>
    );
}

export default ChargeStationDetails;



