import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { AUTHORIZATION_ENDPOINT, REDIRECT_URI, CLIENT_ID, BASE_URL } from '../../Settings';
import { Divider, Col, Row } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Switch, Typography } from '@mui/material';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const FlexPriceConfigurations = ({ values }) => {
	const { t } = useTranslation();
	const [token, setToken] = useState('');
	var OwnerGuid = Cookies.get("ownerGuid");
	const [providers, setProviders] = useState([]);
	const [selectedProvider, setSelectedProvider] = useState('');
	const [selectedProviderName, setSelectedProviderName] = useState('');
	const [importPowerPrices, setImportPowerPrices] = useState([]);
	const [chartColor, setChartColor] = useState('rgba(95,170,57,1)');
	const [showPopup, setShowPopup] = useState(false);
	const [pricingData, setPricingData] = useState([]);
	const [selectedPricingData, setSelectedPricingData] = useState('');
	const [ExportPrices, setExportPrices] = useState([]);
	const [selectedPricingDataLabel, setSelectedPricingDataLabel] = useState('');
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		const fetchToken = Cookies.get("token");
		setToken(fetchToken);
	}, []);

	useEffect(() => {
		const fetchProviders = async () => {
			try {
				const response = await axios.get(`${BASE_URL}/FlexPrice/GetProviderSourcesByOwnerGuid/${OwnerGuid}/${token}`);
				setProviders(response.data);
			} catch (error) {
				console.error("Error fetching providers:", error);
			}
		};

		const fetchPricing = async () => {
			try {
				const response = await axios.get(`${BASE_URL}/FlexPrice/GetPriceCalcByOwnerGuid/${OwnerGuid}/${token}`);
				setPricingData(response.data);
			} catch (error) {
				console.error("Error fetching providers:", error);
			}
		};

		if (token) {
			fetchProviders();
			fetchPricing();
		}
	}, [token]);

	const handleFetchImportData = async (event) => {
		const selectedProvider = event.target.value;
		setSelectedProvider(selectedProvider);
		const provider = providers.find(provider => provider.guid === selectedProvider);
		setSelectedProviderName(provider ? provider.rowKey : '');

		if (selectedProvider === "") {
			setImportPowerPrices([]);
			return;
		}

		try {
			const response = await axios.post(`${BASE_URL}/FlexPrice/CreateorUpdateImportPowerPrices/${token}/${selectedProvider}`);
			setImportPowerPrices(response.data.map(price => {
				const startTimeUtc = new Date(price.startTime).toISOString();
				return {
					label: `${new Date(startTimeUtc).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}`,
					value: price.price
				};
			}));
		} catch (error) {
			console.error("Error fetching import power prices:", error);
		}
	};

	const BarGraphdata = {
		labels: importPowerPrices.map(price => price.label),
		datasets: [
			{
				label: 'Preis Import',
				data: importPowerPrices.map(price => price.value),
				backgroundColor: 'rgba(57,95,170,0.8)',
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 1,
			},
			{
				label: 'Kunden Endpreis',
				data: ExportPrices.map(price => price.value),
				backgroundColor: 'rgba(95,170,57,1)',
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 1,
			}
		]
	};

	const handleFetchExportData = async (event) => {
		const selectedPricingJson = event.target.value;
		setSelectedPricingData(selectedPricingJson);

		if (!selectedPricingJson) {
			setSelectedPricingData('');
			setSelectedPricingDataLabel('');
			setExportPrices([]);
			return;
		}

		try {
			const selectedPricingJsonforGainAndBaseFee = JSON.parse(selectedPricingJson);
			setSelectedPricingDataLabel(selectedPricingJsonforGainAndBaseFee);

			const selectedPricing = JSON.parse(selectedPricingJson);
			const { guid, providerGuid } = selectedPricing;

			const response = await axios.post(`${BASE_URL}/FlexPrice/CreateAndUpdateExportEndPrice/${OwnerGuid}/${guid}/${providerGuid}/${token}`);
			const exportPrices = response.data.map(price => ({
				label: `${new Date(price.startTime).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}`,
				value: price.marketPrice
			}));
			setExportPrices(exportPrices);
		} catch (error) {
			console.error("Error updating or creating price calculation:", error);
		}
	};

	const handleSubmit = async () => {
		const name = document.getElementById('name').value;
		const gain = document.getElementById('gain').value;
		const baseFee = document.getElementById('baseFee').value;

		const priceCalc = {
			Name: name,
			Gain: parseFloat(gain),
			BaseFee: parseFloat(baseFee),
			ProviderGuid: selectedProvider,
			OwnerGuid: OwnerGuid,
			CalcType: 0,
		};

		try {
			const response = await axios.post(`${BASE_URL}/FlexPrice/UpdateOrCreatePriceCalc/${token}`, priceCalc);
			setShowPopup(false);

			const updatedPricingResponse = await axios.get(`${BASE_URL}/FlexPrice/GetPriceCalcByOwnerGuid/${OwnerGuid}/${token}`);
			setPricingData(updatedPricingResponse.data);
			setSelectedPricingData('');
			setExportPrices([]);
		} catch (error) {
			console.error("Error updating or creating price calculation:", error);
		}
	};

	const handleClose = () => {
		setShowPopup(false);
	};

	const handleChange = (field, value) => {
		setSelectedPricingDataLabel({
			...selectedPricingDataLabel,
			[field]: value,
		});
	};

	const handleUpdateSubmit = async () => {
		try {
			if (!selectedPricingDataLabel) {
				console.error("No pricing data selected.");
				return;
			}

			const response = await axios.post(`${BASE_URL}/FlexPrice/UpdateOrCreatePriceCalc/${token}`, selectedPricingDataLabel);

			const updatedPricingResponse = await axios.get(`${BASE_URL}/FlexPrice/GetPriceCalcByOwnerGuid/${OwnerGuid}/${token}`);
			setPricingData(updatedPricingResponse.data);

			const preselectedData = updatedPricingResponse.data.find(pricing => pricing.guid === selectedPricingDataLabel.guid);
			if (preselectedData) {
				setSelectedPricingData(JSON.stringify(preselectedData));
				setSelectedPricingDataLabel(preselectedData);
			}

			const { guid, providerGuid } = selectedPricingDataLabel;
			const exportResponse = await axios.post(`${BASE_URL}/FlexPrice/CreateAndUpdateExportEndPrice/${OwnerGuid}/${guid}/${providerGuid}/${token}`);

			const exportPrices = exportResponse.data.map(price => ({
				label: `${new Date(price.startTime).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}`,
				value: price.marketPrice
			}));
			setExportPrices(exportPrices);
		} catch (error) {
			console.error('Error updating data:', error);
		}
	};

	const hasZeroExportPrice = ExportPrices.some(price => price.value === 0);
	return (
		<div style={{
			width: '100%',


		}}>
			<Row>
				
				<Col xs={24} md={24} >
					<div style={{
						width: "80%",
						border: "none",
						margin: '5% 10% '
					}} >
						<h1 style={{ fontSize: '1.5rem', marginBottom: 0, lineHeight: 1, textAlign: 'center' }}>
							{t("Preis Definition")}
						</h1>
						{/*<h1 style={{ textAlign: 'center' }}> {t("Preis Definition")}</h1>*/}
						<div style={{ marginBottom: '20px' }}>
							<label style={{ marginRight: '10px' }}>{t("Data Source")}</label>
							<select onChange={handleFetchImportData} value={selectedProvider} style={{ width: '100%' }}>
								<option value="">{t("select data source")}</option>
								{providers.map(provider => (
									<option key={provider.guid} value={provider.guid}>{provider.rowKey}</option>
								))}
							</select>
						</div>
						<div style={{ marginBottom: '20px', textAlign: 'center' }}>
							<button style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 20px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', margin: '4px 2px', cursor: 'pointer' }}
								onClick={() => setShowPopup(true)} >{t("Add Pricing Scheme")}</button>
						</div>

						{selectedProvider && (
							<div style={{ marginBottom: '20px' }}>
								<label style={{ marginRight: '10px' }}>{t("Pricing Scheme")}</label>
								<select onChange={handleFetchExportData} value={selectedPricingData} style={{ width: '100%' }}>
									<option value="">{t("please select")}</option>
									{pricingData.map(pricing => (
										<option key={pricing.guid} value={JSON.stringify(pricing)}>{pricing.name}</option>
									))}
								</select>
							</div>
						)}
						{/* {selectedProvider && (
              <div style={{ marginBottom: '20px' }}>
                <label style={{ marginRight: '10px' }}>{t("Pricing Scheme")}</label>
                <select onChange={handleFetchExportData} value={selectedPricingData} style={{ width: '100%' }}>
                  <option value="">{t("Pricing Scheme")}</option>
                  {pricingData.map(pricing => (
                    <option key={pricing.guid} value={JSON.stringify(pricing)}>{pricing.name}</option>
                  ))}
                </select>
              </div>)} */}

						{showPopup && (
							<div className="popup" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', zIndex: '9999', maxWidth: '90%', width: '300px' }}>
								<div style={{ textAlign: 'right' }}>
									<button onClick={handleClose} style={{ background: 'none', border: 'none', fontSize: '16px', cursor: 'pointer' }}> <CloseIcon /></button>
								</div>
								<div>
									<label>{t("Pricing Scheme Name")}:</label>
									<input id="name" type="text" style={{ width: '100%', marginBottom: '10px' }} />
								</div>
								<div>
									<label>{t("Surcharge")}: </label>
									<input id="gain" type="text" style={{ width: '100%', marginBottom: '10px' }} />
								</div>
								<div>
									<label>{t("BSurcharge")}:</label>
									<input id="baseFee" type="text" style={{ width: '100%', marginBottom: '10px' }} />
								</div>

								<button onClick={handleSubmit} style={{ width: '100%' }}>Submit</button>
							</div>
						)}
						{importPowerPrices.length > 0 && selectedPricingData === '' && (
							<div style={{ maxWidth: '90%', margin: '0 auto', marginBottom: '20px' }}>
								<h1 style={{ fontSize: '1rem', marginBottom: 0, lineHeight: 1 }}>{t('Import Power Prices for')} <strong>{selectedProviderName}</strong></h1>
								<div style={{ width: '100%', height: '400px', position: 'relative' }}> {/* Adjust height as needed */}
									<Bar
										data={BarGraphdata}
										options={{
											responsive: true,
											maintainAspectRatio: false,
											title: {
												display: true,
												text: t('Import Power Prices'),
												fontSize: 20,
											},
											legend: {
												display: true,
												position: 'right',
											},
										}}
									/>
								</div>
							</div>
						)}

						{(importPowerPrices.length > 0 || ExportPrices.length > 0) && selectedPricingData !== '' && (
							<div style={{ maxWidth: '90%', margin: '0 auto', marginBottom: '20px' }}>
								<h1 style={{ fontSize: '1rem', marginBottom: 0, lineHeight: 1 } }>{t("Source and Customer end prices")} <strong>{selectedProviderName}</strong> {t("and")} {t("Pricing Scheme")} <strong>{selectedPricingDataLabel.name}</strong> {t("and")} Marge : <strong>{selectedPricingDataLabel.gain}%</strong>, Basis : <strong>{selectedPricingDataLabel.baseFee}</strong></h1>
								{hasZeroExportPrice && <h1 style={{ fontSize: '1rem', marginBottom: 0, lineHeight: 1 }}>{t("NegativeFlexPrice")}</h1>}
								<div style={{ width: '100%', height: '400px', position: 'relative' }}> {/* Adjust height as needed */}
									<Bar
										data={BarGraphdata}
										options={{
											responsive: true,
											maintainAspectRatio: false,
											title: {
												display: true,
												text: 'Import and Export Power Prices',
												fontSize: 20,
											},
											legend: {
												display: true,
												position: 'right',
											},

										}}
									/>
								</div>
								<Divider></Divider>
								<>
									<div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
										<TextField
											label={t("Preis-Profil")}
											value={selectedPricingDataLabel.name || ''}
											onChange={(e) => handleChange('name', e.target.value)}
											style={{ width: '30%', marginRight: '10px' }}
											variant="outlined"
										/>
										<TextField
											label={t("Basis-Preis (ct/kWh)")}
											value={selectedPricingDataLabel.baseFee || ''}
											onChange={(e) => handleChange('baseFee', e.target.value)}
											style={{ width: '30%', marginRight: '10px' }}
											variant="outlined"
										/>
										<TextField
											label={t("Merge (%)")}
											value={selectedPricingDataLabel.gain || ''}
											onChange={(e) => handleChange('gain', e.target.value)}
											style={{ width: '30%', marginRight: '10px' }}
											variant="outlined"
										/>
										<button style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', padding: '10px 20px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', margin: '4px 2px', cursor: 'pointer' }}
											onClick={handleUpdateSubmit}  >{t("Publish")}</button>

									</div>
								</>
							</div>

						)}

					</div>
				</Col>
			</Row>
		</div>
	);
};

export default FlexPriceConfigurations;
