import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Settings";
import { useTranslation } from 'react-i18next';
import { Divider, Col, Row } from 'antd';
import AccountMenu from '../Account/AccountMenu';
import Cookies from "js-cookie";
import { atom, useAtom, useSetAtom } from 'jotai';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Button, } from "@material-ui/core";
import userGroupsTypes from "../services/userGroupsTypes";
import { useNavigate } from 'react-router-dom';
import UserGroupsMenu from "./UserGroupsMenu";




const userGuidAtom = atom(null)
const userGroupsAtom = atom([])



const UserGroups = () => {
    var token = Cookies.get("token");
    const [userGroups, setUserGroups] = useState([]);
    const [addUser, setAddUser] = useState(false);
    const [users, setUsers] = useState();
    const { t } = useTranslation();
    const [userGuid1, setUserGuid1] = useState();
    const [userGroupName, setUserGroupName] = useState();
    console.log("userGuid1", userGuid1)
    const setUserGuid = useSetAtom(userGuidAtom);
    const setUserGroupsAtom = useSetAtom(userGroupsAtom);
    localStorage.setItem('activePage', 'UserGroups');

    const navigate = useNavigate();
    const GroupTypeMask =
        userGroupsTypes.PublicGroup |
        userGroupsTypes.HasPrice |
        userGroupsTypes.PayByApp |
        userGroupsTypes.Invoice |
        userGroupsTypes.MonthlyOverview |
        userGroupsTypes.ExternalStations |
        userGroupsTypes.RequiresSubscription |
        userGroupsTypes.GenerateReport;



    var ownerGuid = Cookies.get("ownerGuid");

    useEffect(() => {
        console.log("Effect is running");
        const api = axios.create({
            baseURL: BASE_URL,
        });

        const fetchData = async () => {
            try {
                const response = await api.get(
                    `UserGroup/GetUserGroups/${ownerGuid}/${token}`
                );
                console.log("response", response.data);
                if (response.data === undefined) {
                    // Display a proper message when response.data is null
                    console.log("No user groups found");
                } else {
                    setUserGroups(response.data);
                  
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [ownerGuid, token, addUser]); // Include addUser as a dependency


    useEffect(() => {
        var pureValue = getBitFromMask(userGroups.groupType, GroupTypeMask);
        console.log("purevalue", pureValue);
    });

    var getBitFromMask = function (value, mask) {
        return value & mask;
    };

    // var pureValue = getBitFromMask(userGroups[0].groupType,GroupTypeMask);


    const userGroupNames = [
        "Name",
        t('Price'),
        //"DirectPay (PayByApp)",
        //"Invoice",
        //"Reporting (GenerateReport)",
        // "Subscription (RequiresSubscription)",
        "Active",
        // "External Stations",
    ];
    //   const allUserGroupNames = [...userGroupNames, ...(userGroups.map(group => group.name))];

    const iconValues = {
        true: "✅", // You can replace these with appropriate icons
        false: "❌",
    };

    const displayBitmaskValues = (bitmask) => {
        const bitmaskValues = {
            PublicGroup: 0x1,
            HasPrice: 0x2,
            PayByApp: 0x4,
            Invoice: 0x8,
            GenerateReport: 0x10,
            ExternalStations: 0x20,
            RequiresSubscription: 0x40,
            MonthlyOverview: 0x80,
        };

        const displayedValues = {};

        Object.entries(bitmaskValues).forEach(([key, mask]) => {
            displayedValues[key] = getBitFromMask(bitmask, mask) !== 0;
        });

        return displayedValues;
    };

    const currentRows = userGroups; // Assuming userGroups is the data for your table
    async function createUserGroup() {
        // Replace with your actual API URL


        try {



        } catch (error) {
            console.error('Error creating user group:', error);
            // Handle error appropriately
        }
    }
    const [triggerApiCall, setTriggerApiCall] = useState(false);

    const callApi = async () => {
        const api = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json', // Explicitly setting Content-Type header
            },
        });
        console.log("api", api)
        try {
            const data = {
                Name: "",
                RowKey: "",
                Description: "",
                PartitionKey: ""
            };
            const response = await api.post(`UserGroup/CreateUserGroup/${ownerGuid}/${token}`, JSON.stringify(data));
            console.log("response of create userGroup", response.data)
            setUserGuid(response.data.guid);
            setUserGroupsAtom(response.data);
            setUserGuid1(response.data.rowKey);
            
            return response.data;
            // Reset trigger to avoid repeated calls
            setTriggerApiCall(false);

            // Optional: Additional logic after successful API call
        } catch (error) {
            console.error('Error in API call:', error);
            // Handle the error appropriately
        }
    };

    console.log("setUserGuid", userGuidAtom)


    const handleClick = () => {
        callApi();
        // navigate("/UserGroupsMenu");
        setAddUser(true)
    };
    const editRoWhandleClick = (row) => {
        // navigate to "/userGroup/userGroupProperties" with the row as state
        setAddUser(true);
        setUserGuid(row.guid);
        setUserGroupsAtom(row);
        setUserGuid1(row.rowKey);
        setUserGroupName(row.name);
        // navigate("/userGroup/userGroupProperties", { state: row });

        // Optionally, you can also setAddUser(true) if needed
        // setAddUser(true);
    };
    return (

        <div style={{
            width: '100%',


        }}>
            <Row>
                
                {addUser ?
                    (
                        <Col xs={24} md={24} >
                            <UserGroupsMenu setAddUser={setAddUser} userGuid1={userGuid1} userGroupName={userGroupName}></UserGroupsMenu>
                        </Col>) : (
                        <Col xs={24} md={24} >
                            <div style={{
                                width: "95%",
                                border: "none",
                                margin: '2% 2% '
                            }} >
                                <TableContainer component={Paper}>
                                    <Table aria-label="a dense table">
                                        <TableHead
                                            className="list-table-header"

                                        >
                                            <TableRow>
                                                {userGroupNames.map((value, index) => (
                                                    <TableCell key={index} align="left">
                                                        {value.includes("(") ? (
                                                            <>
                                                                <div>{value.split(" ")[0]}</div>
                                                                <div>({value.split("(")[1].split(")")[0]})</div>
                                                            </>
                                                        ) : (
                                                            value
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {currentRows.map((row, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    {userGroupNames.map((value, colIndex) => (
                                                        <TableCell key={colIndex} align="left" onClick={() => editRoWhandleClick(row)} style={{ cursor: 'pointer' }}>
                                                            {value === "Name" && row.name}
                                                            {value === t('Price') &&
                                                                iconValues[displayBitmaskValues(row.groupType).HasPrice]}
                                                            {/*{value === "DirectPay (PayByApp)" &&*/}
                                                            {/*  iconValues[displayBitmaskValues(row.groupType).PayByApp]}*/}
                                                            {/*{value === "Invoice" &&*/}
                                                            {/*  iconValues[displayBitmaskValues(row.groupType).Invoice]}*/}
                                                            {/*{value === "Overview (Monthly)" &&*/}
                                                            {/*  iconValues[displayBitmaskValues(row.groupType).MonthlyOverview]}*/}
                                                            {/*{value === "Reporting (GenerateReport)" &&*/}
                                                            {/*  iconValues[displayBitmaskValues(row.groupType).GenerateReport]}*/}
                                                            {value === "Active" &&
                                                                iconValues[row.isActive]}
                                                            {/* {value === "External Stations" &&
                iconValues[displayBitmaskValues(row.groupType).ExternalStations]}
              {value === "RequiresSubscription" &&
                iconValues[displayBitmaskValues(row.groupType).RequiresSubscription]} */}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Divider />
                                <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                                    <Button
                                        variant="contained"
                                        type="primary"
                                        color="primary"
                                        onClick={handleClick}
                                    >
                                        {t("Add Usergroups")}
                                    </Button>
                                </div>
                            </div>
                        </Col>)}
            </Row>
        </div>
    );
};
export { userGuidAtom, userGroupsAtom };
export default UserGroups;
