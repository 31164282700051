import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import '../../../src/css/baseDataMenu.css';
import BackButton from '../Elements/BackButton';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        variant="fullWidth" // Ensure tabs take full width
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#FFEF3F',
        transform: 'translateY(-2px)',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.7) !important',
        '&.Mui-selected': {
            color: '#fff !important',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32) !important',
        },
       
    }),
);

const UserGroupsMenu = ({ setAddUser, userGuid1 ,userGroupName}) => {
    console.log("userguid1", userGuid1);
    
    console.log("userGroupName", userGroupName);
    const [selectedTab, setSelectedTab] = useState(1); // Start from the first actual tab
    const { t } = useTranslation();
    const links = [
        {
            page: t('Properties'),
            link: 'UserGroupProperties',
            enabled: true
        },
        {
            page: t('Opening Hours'),
            link: 'UserGroupProfiles',
            enabled: true
        },
        {
            page: t('Members'),
            link: 'UserGroupMembers',
            enabled: true
        },
        {
            page: t('Charging Stations'),
            link: 'UserGroupChargingStations',
            enabled: true
        },
        {
            page: t('Invites'),
            link: 'Invites',
            enabled: true
        },
    ];

    const handleTabClick = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleBackClick = () => {
        setAddUser(false);
    };

    return (
        <Box sx={{
            bgcolor: "#61993A", width: '100%' }}>
            <div className="tabs-container" style={{ width: '100%' }}>
                <div className="tabs" style={{ width: '100%' }}>
                    <StyledTabs value={selectedTab} onChange={handleTabClick} aria-label="styled tabs example" sx={{ width: '100%' }}>
                        <StyledTab
                            onClick={handleBackClick}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ArrowBackIosIcon />
                                    <span style={{ marginLeft: 1}}>{userGroupName}</span>
                                </div>
                            }
                        />
                        {links.map(({ link, page, enabled }, index) => (
                            <StyledTab key={index + 1} label={page} disabled={!enabled} />
                        ))}
                    </StyledTabs>

                </div>
                <div className="tab-content" style={{ backgroundColor: '#f0f0f0', padding: '16px', width: '100%' }}>
                    {links[selectedTab - 1] && (
                        <DynamicComponentLoader componentName={links[selectedTab - 1].link} userGuid1={userGuid1} />
                    )}
                </div>
            </div>
        </Box>
    );
};

const DynamicComponentLoader = ({ componentName, userGuid1 }) => {
    const ComponentToLoad = React.lazy(() =>
        import(`./${componentName}`)
    );

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <ComponentToLoad userGuid1={userGuid1} />
        </React.Suspense>
    );
};

export default UserGroupsMenu;
